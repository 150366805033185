const swiftSliders = [
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-1.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-1.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-2.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-2.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-3.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-3.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-4.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-4.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-5.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-5.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-6.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-6.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-7.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-7.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-8.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-8.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-9.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-9.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-10.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-10.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-11.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-11.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-12.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/exterior/ex-12.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-1.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-1.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-2.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-2.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-3.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-3.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-4.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-4.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-5.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-5.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-6.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-6.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-7.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-7.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-8.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-8.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-9.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-9.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-10.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-10.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-11.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-11.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-12.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-12.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-13.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-13.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-14.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-14.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-15.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-15.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-16.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-16.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-17.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-17.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-18.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-18.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-19.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-19.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-20.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-20.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-21.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-22.webp',
  },
  {
    original:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-23.webp',
    thumbnail:
      'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/slider/interior/in-23.webp',
  },
 
];

const swiftColors = [
  {
    id: 1,
    img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/colors/car-01.webp',
  },
  {
    id: 2,
    img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/colors/car-02.webp',
  },
  {
    id: 3,
    img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/colors/car-03.webp',
  },
  {
    id: 4,
    img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/colors/car-06.webp ',
  },
  {
    id: 5,
    img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/colors/car-04.webp',
  },
  {
    id: 6,
    img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/colors/car-05.webp',
  },

  {
    id: 7,
    img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/colors/car-07.webp',
  },
  {
    id: 8,
    img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/colors/car-09.webp',
  },
  {
    id: 9,
    img: 'https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/cars/swift-2024/colors/car-10.webp',
  },
];

const swiftVariants = [
  {
    id: 1,
    title: 'lxi',
    transmission: 'manual',

    price: '₹ 6,49,001 Lakh',
  },
  {
    id: 2,
    title: 'vxi',
    transmission: 'manual',

    price: '₹ 7,29,501 Lakh',
  },
  {
    id: 3,
    title: 'vxi ags',
    transmission: 'automatic',

    price: '₹ 7,74,500 Lakh',
  },
  {
    id: 4,
    title: 'vxi (o)',
    transmission: 'manual',

    price: '₹ 7,56,500 Lakh',
  },
  {
    id: 5,
    title: 'vxi (o) AGS',
    transmission: 'Automatic',

    price: '₹ 8,01,501 Lakh',
  },
  {
    id: 6,
    title: 'zxi',
    transmission: 'manual',

    price: '₹ 8,29,500 Lakh',
  },
  {
    id: 7,
    title: 'zxi ags',
    transmission: 'automatic',

    price: '₹ 8,74,500 Lakh',
  },
  {
    id: 8,
    title: 'zxi+',
    transmission: 'manual',

    price: '₹ 8,99,501 Lakh',
  },
  {
    id: 10,
    title: 'zxi+ DT',
    transmission: 'manual',
    price: '₹ 9,14,499 Lakh',
  },
  {
    id: 9,
    title: 'zxi+ ags',
    transmission: 'automatic',

    price: '₹ 9,44,500 Lakh',
  },
  {
    id: 11,
    title: 'zxi+ ags (D)',
    transmission: 'automatic',

    price: '₹ 9,59,500 Lakh',
  },
];

const swiftMileage = [
  {
    id: 1,
    fueltype: 'petrol',
    transmission: 'manual',
    mileage: '24.8 KM/L',
  },
  {
    id: 2,
    fueltype: 'petrol',
    transmission: 'automatic',
    mileage: '25.75 KM/L',
  },
];

export { swiftSliders, swiftColors, swiftVariants, swiftMileage };

import React from "react";
// import { useEffect } from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import Header from "../../components/header/Header";

import { Tab } from "@headlessui/react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import { CgSpinner } from 'react-icons/cg';
import { Helmet } from "react-helmet";
import { CarEnquiryDown } from "../Forms/CarEnquiryDown";

function SwiftCNG() {
  return (
    <>
      <Helmet>
        <title>
          Maruti Swift S-CNG Price in Hyderabad | Swift S-CNG Mileage &
          Specifications
        </title>
        <meta
          name="title"
          content="Maruti Swift CNG Price in Hyderabad | Swift CNG Mileage & Specifications"
        />
        <meta
          name="description"
          content="Maruti Suzuki Swift S-CNG Price in Hyderabad & Secunderabad. Swift CNG Mileage 31.6KMPL. Best price and offers on Maruti Swift S-CNG at Saboo Maruti. Visit your nearest Saboo Maruti Showroom for best deals."
        />
        <meta
          name="keywords"
          content="swift s-cng on road price, swift s-cng offers, swift cng price, swift cng service center, maruti swift cng on road price, maruti swift cng offers, maruti swift cng price, maruti swift cng service center, maruti alto service center near me, maruti suzuki Swift CNG price, maruti suzuki swift cng on road price, maruti suzuki swift cng offers,maruti suzuki swift cng on road price, swift cng 2022 offers, swift cng 2022 price, Swift CNG on road price in hyderabad, breza offers in hyderabad, Swift CNG price in hyderabad, maruti Swift CNG 2022 price, maruti Swift CNG 2022 on road price, maruti Swift CNG 2022 offers, maruti Swift CNG 2022 price in hyderabad, maruti Swift CNG 2022 on road price in hyderabad, Maruti Suzuki Swift CNG 2022 price, Maruti Suzuki Swift CNG 2022 on road price, maruti suzuki Swift CNG 2022 offers,  vitara Swift CNG offers, Vitara Swift CNG price, vitara Swift CNG on road price, vitara Swift CNG 2022 offers, vitara Swift CNG 2022 price, vitara Swift CNG 2022 on road price, vitara Swift CNG 2022 offers"
        />
        <meta name="author" content="Broaddcast" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.saboomaruti.in/cng/maruti-alto-800-cng-on-road-price-in-hyderabad"
        />
        <meta
          property="og:title"
          content="Maruti Swift CNG Price in Hyderabad | Swift CNG CNG Mileage & Specifications | Saboo Maruti"
        />
        <meta
          property="og:description"
          content="Maruti Suzuki Swift CNG Price in Hyderabad & Secunderabad. Swift CNG CNG Mileage 31.6KMPL. Best price and offers on Maruti Swift CNG at Saboo Maruti. Visit your nearest Saboo Maruti Showroom for best deals."
        />
        <meta property="og:image" content="../img/og-tags/alto.webp" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://x.com/saboorksmaruti" />
        <meta
          property="twitter:title"
          content="Maruti Swift CNG Price in Hyderabad | Swift CNG CNG Mileage & Specifications | Saboo Maruti"
        />
        <meta
          property="twitter:description"
          content="Maruti Suzuki Swift CNG Price in Hyderabad & Secunderabad. Swift CNG Mileage 31.6KMPL. Best price and offers on Maruti Swift CNG at Saboo Maruti. Visit your nearest Saboo Maruti Showroom for best deals."
        />
        <meta
          property="twitter:image"
          content="../img/og-tags/Swift CNG.webp"
        />
      </Helmet>
      <Header />
      <img
        src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/CNG/alto/Website%2BSaboo%2BRKS%2BMotor%2BMaruti%2BSuzuki%2BArena%2BMarch%2BHoli%2BOffer.webp"
        className="max-w-full w-full lg:mt-16"
        alt="1"
      />
      <div className="container mx-auto pt-6 md:px-3 py-6">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2">
          <div className="space-y-3 justify-between px-6">
            <p className="uppercase font-bold text-4xl">New Swift S-CNG</p>
            <p className="font-bold text-green-900">
              7.90 lakh <sup>*</sup>
              <p className="font-light text-red-400">ex-show room price</p>
            </p>

            <p className="text-s">
              Limitless thrills come together with green mobility in the Swift
              CNG. It is equipped with Factory-fitted-S-CNG technology that
              offers reliable performance, advanced safety, and supreme fuel
              efficiency.
            </p>
            <br></br>
            <p className="text-s">
              S-CNG technology is available in the <strong>VXI</strong>{" "}
              and&nbsp;
              <strong>ZXI</strong>&nbsp; variants of Swift. The factory-fitted
              CNG setup comes with a leak-proof and corrosion-resistant design
              that has been extensively tested for safety and durability. Thanks
              to an Intelligent Injection System and dual interdependent ECUs,
              the Swift CNG delivers consistent performance in different driving
              conditions. It also has features like a micro-switch for safety
              while filling, an NGV receptacle for quicker CNG refilling, and a
              CNG fuel level indicator.
            </p>
            <br></br>
            <div className="flex flex-row">
              <div className="basis-1/1">
                <img
                  src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/CNG/alto/icon1.png"
                  className="max-w-full w-full pr-3"
                  alt="1"
                />
              </div>
              <div className="basis-1/1">
                <img
                  src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/CNG/alto/icon2.png"
                  className="max-w-full w-full pr-3"
                  alt="1"
                />
              </div>
              <div className="basis-1/1">
                <img
                  src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/CNG/alto/icon3.png"
                  className="max-w-full w-full pr-3"
                  alt="1"
                />
              </div>
            </div>
          </div>
          <div className="object-contain h-50 w-100 object-right pt-14">
            <img
              src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/data/swift/colors/Maruti-Suzuki-Swift-Solid-Fire-Red.webp"
              alt="vehicle_banner"
            />
          </div>
        </div>
      </div>
      {/* insert form here  */}

      {/* <SwiftCarEnquiry /> */}
      <CarEnquiryDown title="Swift CNG" carName="Swift CNG" />
      <div className="container mx-auto pt-6 lg:px-0 md:px-3 py-6">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2">
          <div className="space-y-3 justify-between px-6">
            <p className="uppercase font-bold text-4xl">Swift S-CNG features</p>
            <img
              src="https://images-saboomaruti-in.s3.ap-south-1.amazonaws.com/Arena/logo/swift-cng-logo.webp"
              className="h-8"
              alt="alto-logo"
            />
          </div>
        </div>
      </div>
      <div className="container mx-auto lg:-mt-10">
        <Accordian />
      </div>
    </>
  );
}

// const SwiftCarEnquiry = ({ title, carName }) => {
//   const [name, setName] = useState('');
//   const [phone, setPhone] = useState('');
//   const [model, setModel] = useState('Swift CNG');
//   // const [method, setMethod] = useState();
//   const [loading, setLoading] = useState(false);
//   const [outlet, setOutlet] = useState('');
//   const [submitted, setSubmitted] = useState(false);
//   // Inside your component function
//   // const [showToast, setShowToast] = useState(false);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (!name || !phone) {
//       toast.error('Please fill in all required fields.');
//       return;
//     }
//     setLoading(true);
//     try {
//       await axios
//         .post('https://saboogroups.com/admin/api/arena-onRoadPrice', {
//           name: name,
//           phone: phone,
//           // email: email,
//           model: model,
//           outlet: outlet,
//         })
//         .then((res) => {
//           toast.success('Enquiry sent successfully');
//         })
//         .catch((err) => {
//           setLoading(false);
//           toast.error('Something went wrong!');
//           console.log(err);
//         });
//     } catch (error) {
//       // toast.error("Something went wrong!");
//       setLoading(false);
//     }

//     try {
//       await axios
//         .post('https://arena-backend-git-main-arenas-projects.vercel.app/onRoadPrice', {
//           name: name,
//           phone: phone,
//           // email: email,
//           model: model,
//           outlet: outlet,
//         })
//         .then((res) => {
//           toast.success('Enquiry sent successfully');
//         })
//         .catch((err) => {
//           setLoading(false);
//           toast.error('Something went wrong!');
//           console.log(err);
//         });
//     } catch (error) {
//       // toast.error("Something went wrong!");
//       setLoading(false);
//     }

//     // Second API call
//     await axios
//       .get(
//         `https://www.smsstriker.com/API/sms.php?username=saboorks&password=LqHk1wBeI&from=RKSMOT&to=${phone}&msg=Thank you for showing interest in Maruti Suzuki.
//    Our Sales consultant will contact you shortly.

//    Regards
//    RKS Motor Pvt. Ltd.
//    98488 98488
//    www.saboomaruti.in
//    www.saboonexa.in&type=1&template_id=1407168967467983613`
//       )
//       .then((res) => {
//         console.log('SMS API Response:', res.data);
//         setSubmitted(true);
//         setLoading(false);
//       })
//       .catch((err) => {
//         console.error('Error sending SMS:', err);
//         setSubmitted(true);
//         setLoading(false);
//       });
//   };

//   useEffect(() => {
//     if (submitted) {
//       document.getElementById('arenaCarEnq2').submit();
//     }
//   }, [submitted]);

//   const pattern = /^(?![6-9]{10}$)(?!.*(\d)(?:-?\1){9})[6-9]\d{9}$/;
//   if (phone !== '' && phone.length === 10) {
//     if (!pattern.test(phone)) {
//       toast.error('Enter valid phone number', {
//         theme: 'colored',
//       });
//     }
//   }

//   return (
//     <div className='bg-[#1b72b7] py-12 mt-12'>
//       <div className='container mx-auto space-y-5 lg:px-0 px-5'>
//         <h3 className='text-xl font-normal text-white normal-case'>
//           Get Swift CNG On-Road price in Hyderabad
//         </h3>
//         <form
//           id='arenaCarEnq2'
//           action={
//             pattern.test(phone) && phone.length === 10
//               ? 'https://crm.zoho.in/crm/WebToLeadForm'
//               : '#'
//           }
//           name='WebToLeads54158000083979838'
//           method={'POST'}
//           acceptCharset='UTF-8'
//         >
//           <input
//             type='text'
//             className='hidden'
//             name='xnQsjsdp'
//             value='c74cc4baa2079f2637d12188693a8bb7a822a54f015337983612fcbc54e9f529'
//           />
//           <input type='hidden' name='zc_gad' id='zc_gad' value='' />
//           <input
//             type='text'
//             className='hidden'
//             name='xmIwtLD'
//             value='adcef2507910e0e3ba3fffde446eb242f3dba817a00c872b6a7d471bc1ce61d0bd840c68a483b37a9012f6016a3ceeb4'
//           />
//           <input
//             type='text'
//             className='hidden'
//             name='actionType'
//             value='TGVhZHM='
//           />
//           <input
//             type='text'
//             className='hidden'
//             name='returnURL'
//             value='https://www.saboomaruti.in/thank-you-for-contact-us'
//           />
//           <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4'>
//             <div>
//               <input
//                 className='border h-10 outline-none px-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500'
//                 placeholder='Name'
//                 id='Last_Name'
//                 name='Last Name'
//                 onChange={(e) => setName(e.target.value)}
//                 required
//               />
//             </div>
//             <div>
//               <input
//                 className='border h-10 outline-none px-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500'
//                 placeholder='Enter your phone number'
//                 minlength='10'
//                 maxlength='10'
//                 id='Phone'
//                 name='Phone'
//                 value={phone}
//                 required
//                 minLength='10'
//                 maxLength='10'
//                 onChange={(e) =>
//                   setPhone(
//                     e.target.value.replace(/[^1-9 ]/g, '') &&
//                       e.target.value.replace(/ /g, '')
//                   )
//                 }
//               />
//               {phone.length > 7 && phone.length < 10 ? (
//                 <small className='text-red-500'>
//                   Phone number must be 10 digits
//                 </small>
//               ) : !pattern.test(phone) && phone.length === 10 ? (
//                 <small className='text-red-500'>Phone number is invalid</small>
//               ) : (
//                 ''
//               )}
//             </div>
//             <div>
//               <select
//                 id='LEADCF6'
//                 name='LEADCF6'
//                 onChange={(e) => setModel(e.target.value)}
//                 className='block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
//                 defaultValue='Swift CNG'
//               >
//                 <option disabled>Select Model</option>

//                 <option value='Swift CNG'>Swift CNG</option>
//               </select>
//             </div>
//             <div>
//               <select
//                 id='LEADCF23'
//                 name='LEADCF23'
//                 onChange={(e) => {
//                   const selectedValue = e.target.value;

//                   // Check if the selected option is not "Select Outlet"
//                   if (selectedValue !== 'Select Outlet') {
//                     // Perform the backend call or set the model state here
//                     setOutlet(selectedValue);
//                   }
//                 }}
//                 defaultValue='Select Outlet'
//                 className='block w-full h-10 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
//               >
//                 <option disabled>Select Outlet</option>
//                 <option value='Somajiguda'>Somajiguda</option>
//                 <option value='Malakpet'>Malakpet</option>
//                 <option value='Secunderabad'>Secunderabad</option>
//                 <option value='Kushaiguda'>Kushaiguda</option>
//                 <option value='Kompally'>Kompally</option>
//                 <option value='Shamirpet'>Shamirpet</option>
//                 <option value='Narsingi'>Narsingi</option>
//                 <option value='Kodangal'>Kodangal</option>
//               </select>
//             </div>
//           </div>
//           {/* <div className='flex items-center space-x-1 my-3'>
//           <input id='comments' name='comments' type='checkbox' required />
//           <label htmlFor='comments' className='font-medium text-gray-200'>
//             I Agree
//           </label>
//         </div>
//         <p className='text-gray-200 mb-2'>
//           <span className='font-semibold'>Disclaimer :</span> I agree that
//           by clicking the ‘Submit’ button below, I am explicitly soliciting
//           a call / Message from Saboo Maruti (RKS Motor Pvt. Ltd) or its
//           Representatives on my ‘Mobile’
//         </p> */}
//           <button
//             type='submit'
//             onClick={handleSubmit}
//             className='h-10 inline-flex justify-center mr-3 py-2 px-4  mt-4 mb-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-800 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
//           >
//             {loading ? (
//               <div className='flex items-center justify-center'>
//                 <CgSpinner className='animate-spin h-5 mr-2 text-white' />
//                 Loading
//               </div>
//             ) : (
//               'SUBMIT'
//             )}
//           </button>
//           <div className='flex items-start py-1 '>
//             <div className='ml-2 text-sm'>
//               <label
//                 htmlFor='disclaimer'
//                 className='font-medium text-gray-700 italic'
//               >
//                 <span className='text-white font-bold'>Disclaimer</span>
//                 <span className='text-white font-extralight'>
//                   : By clicking 'SUBMIT', you agree to our
//                 </span>
//                 <a
//                   href='/maruti-car-terms-and-conditions'
//                   target='_blank'
//                   rel='noopener noreferrer'
//                   className='px-2  text-sm font-bold  text-red-700 '
//                 >
//                   Terms and Conditions
//                 </a>
//               </label>
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

function Accordian() {
  return (
    <>
      <Tab.Group>
        <div className="flex items-center justify-end">
          <Tab.List className="space-x-1 p-5">
            <Tab
              className={({ selected }) =>
                selected
                  ? "bg-[#1b72b7] text-gray-200 px-4 py-2 rounded shadow"
                  : "bg-gray-300 text-black hover:bg-[#124f80] hover:text-gray-300 mb-3 px-4 py-2 rounded shadow"
              }
            >
              Features
            </Tab>

            <Tab
              className={({ selected }) =>
                selected
                  ? "bg-[#1b72b7] text-gray-200 px-4 py-2 rounded shadow"
                  : "bg-gray-300 text-black hover:bg-[#124f80] hover:text-gray-300 mb-3 px-4 py-2 rounded shadow"
              }
            >
              Specifications
            </Tab>
          </Tab.List>
        </div>
        <div>
          <Tab.Panels>
            {/* exterior */}
            <Tab.Panel>
              <div className="flex items-center bg-gray-100  justify-between">
                <p className="flex-1 border px-5 py-3">Features</p>
                <p className="flex-1 border px-5 py-3">VXI CNG</p>
                <p className="flex-1 border px-5 py-3">ZXI CNG</p>
              </div>

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">Exterior</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          LED Rear Combination Lamp
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          LED High Mounted Stop Lamp
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Body Coloured Outside Rear View Mirrors
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Side Turn Indicators on Outside Rear View Mirrors
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Wheels</p>
                        <p className="flex-1 border px-5 py-3">Steel</p>
                        <p className="flex-1 border px-5 py-3">Alloy</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Full Wheel Cover
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Body Coloured Bumpers
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Body Coloured Outside Front Door Handles
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">INTERIOR</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Leather^ Wrapped Steering Wheel
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Multi-Information Display
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Outside Temperature Display
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Silver Ornament on Front Door Armrest
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Front Seat Back Pocket (Co-Driver side)
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Chrome Parking Brake Lever Tip
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">IP Ornament</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Gear Shift Knob in Piano Black Finish
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Chrome Inside Door Handles
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Front Dome Lamp
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Tachometer</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Co- Driver Side Sunvisor with Vanity Mirror
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Driver Side Sunvisor with Ticket Holder
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">
                        safety and security
                      </span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          ABS with EBD and Brake Assist
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Dual Front Airbags
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Front Fog Lamps
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          ISOFix Child Seat Anchorages
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Front Seat Belt with Pre-Tensioner and Force Limiter
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Pinch Guard Power Window (Driver Side)
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Engine Immobilizer
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Speed-Sensitive Automatic Door Lock
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Day and Night Adjustable Inside Rear View Mirror
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          High Speed Alert System*
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Seat Belt Reminder & Buzzer(Driver & Co-Driver Side)
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Reverse Parking Sensors
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Security Alarm System
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Pedestrian Protection Compliance
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">
                        Audio & Entertainment
                      </span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          All New Feather Touch Audio System
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">-</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Navigation System with Live Traffic Update (through
                          Smartplay Studio App)
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          AHA Platform (Through Smartplay Studio App){" "}
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Steering Mounted Audio & Calling Controls
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Door Speakers (4 Speakers)
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Tweeters (2 Tweeters)
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Roof Antenna</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          17.78 cm Touchscreen Smartplay Studio
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Voice Command
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Smartphone Connect (Android Auto and Apple CarPlay)
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Remote Control (through Smartplay Studio App)
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          AUX-in, USB & Bluetooth Connectivity
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">
                        comfort and convenience
                      </span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Engine Push Start-Stop Button with Smart Key
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Automatic Climate Control
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Remote Keyless Entry System
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Manual Air Conditioner with Heater{" "}
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">-</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Front Accessory Socket
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Central Door Locking
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Electromagnetic Back Door Opener
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Gear Shift Indicator
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Power Windows (Front + Rear)
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Electrically Adjustable Outside Rear View Mirrors
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Electrically Foldable Outside Rear View Mirrors{" "}
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Auto Down Power Window (Driver Side){" "}
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Auto Up Power Window (Driver Side)
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Power & Tilt Steering{" "}
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Height Adjustable Driver Seat
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Adjustable Front Seat Headrests
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Luggage Room Lamp
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Rear Defogger
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Rear Wiper & Washer
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Adjustable Rear Seat Headrests
                        </p>
                        <p className="flex-1 border px-5 py-3">-</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          60:40 Split Rear Seat
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Rear Parcel Shelf
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Low Fuel Warning Lamp
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Door Ajar Warning Lamp
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Headlamp On Reminder
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">
                        colour variants
                      </span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Solid Fire Red
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Pear Metallic Midnight Blue
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Pearl Arctic White
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>

                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Pearl Metallic Lucent Orange
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Metallic Magma Grey
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Metallic Silky Silver
                        </p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </Tab.Panel>

            {/* interior */}
            <Tab.Panel>
              <div className="flex items-center bg-gray-100  justify-between">
                <p className="flex-1 border px-5 py-3">Specifications</p>
                <p className="flex-1 border px-5 py-3">VXI CNG</p>
                <p className="flex-1 border px-5 py-3">ZXI CNG</p>
              </div>

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">Dimensions</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Length</p>
                        <p className="flex-1 border px-5 py-3">3845 mm</p>
                        <p className="flex-1 border px-5 py-3">3845 mm</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Width</p>
                        <p className="flex-1 border px-5 py-3">1735 mm</p>
                        <p className="flex-1 border px-5 py-3">1735 mm</p>
                      </div>

                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Height</p>
                        <p className="flex-1 border px-5 py-3">
                          1530 mm (Unladen)
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          1530 mm (Unladen)
                        </p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Wheelbase</p>
                        <p className="flex-1 border px-5 py-3">2450 mm</p>
                        <p className="flex-1 border px-5 py-3">2450 mm</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Track, Front (mm)
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          1530 (165/80R14)
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          1520 (185/65R15)
                        </p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Track, rear (mm)
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          1530 (165/80R14)
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          1520 (185/65R15)
                        </p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">engine</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Engine Type</p>
                        <p className="flex-1 border px-5 py-3">
                          Advanced K Series Dual Jet, Dual VVT Engine{" "}
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          Advanced K Series Dual Jet, Dual VVT Engine
                        </p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Fuel Type</p>
                        <p className="flex-1 border px-5 py-3">
                          Gasoline & CNG{" "}
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          Gasoline & CNG
                        </p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Engine Capacity{" "}
                        </p>
                        <p className="flex-1 border px-5 py-3">1197 cc</p>
                        <p className="flex-1 border px-5 py-3">1197 cc</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Emission Type{" "}
                        </p>
                        <p className="flex-1 border px-5 py-3">BS VI</p>
                        <p className="flex-1 border px-5 py-3">BS VI</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Max Power (CNG)
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          57 kW @ 6000 rpm
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          57 kW @ 6000 rpm
                        </p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Max Torque (CNG){" "}
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          98.5 Nm @ 4300 rpm
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          98.5 Nm @ 4300 rpm
                        </p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Number of Cylinders{" "}
                        </p>
                        <p className="flex-1 border px-5 py-3">4</p>
                        <p className="flex-1 border px-5 py-3">4</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">Transmission</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">5MT</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                        <p className="flex-1 border px-5 py-3">✔</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">brakes</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Front</p>
                        <p className="flex-1 border px-5 py-3">Disc</p>
                        <p className="flex-1 border px-5 py-3">Disc</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Rear</p>
                        <p className="flex-1 border px-5 py-3">Drum</p>
                        <p className="flex-1 border px-5 py-3">Drum</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">Steering</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Turning Radius
                        </p>
                        <p className="flex-1 border px-5 py-3">4.8 m</p>
                        <p className="flex-1 border px-5 py-3">4.8 m</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Power Steering Type
                        </p>
                        <p className="flex-1 border px-5 py-3 ">Electric</p>
                        <p className="flex-1 border px-5 py-3">Electric</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">Suspension</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Front</p>
                        <p className="flex-1 border px-5 py-3">
                          Mac Pherson Strut
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          MacPherson Strut
                        </p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Rear</p>
                        <p className="flex-1 border px-5 py-3">Torsion Beam</p>
                        <p className="flex-1 border px-5 py-3">Torsion Beam </p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">Fuel Tank</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Fuel Tank Capacity
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          Gasoline: 37L CNG: 55L (water equivalent)
                        </p>
                        <p className="flex-1 border px-5 py-3">
                          Gasoline: 37L CNG: 55L (water equivalent)
                        </p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">Tyre Size</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Steal</p>
                        <p className="flex-1 border px-5 py-3">165/80 R14 </p>
                        <p className="flex-1 border px-5 py-3">185/65 R15</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">Alloy</p>
                        <p className="flex-1 border px-5 py-3">165/80 R14 </p>
                        <p className="flex-1 border px-5 py-3">185/65 R15</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">
                        seating capacity
                      </span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Seating Capacity
                        </p>
                        <p className="flex-1 border px-5 py-3">5</p>
                        <p className="flex-1 border px-5 py-3">5</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between border px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                      <span className="text-base uppercase">Weight</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black font-bold`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Kerb Weight (kg){" "}
                        </p>
                        <p className="flex-1 border px-5 py-3">980-985 kg </p>
                        <p className="flex-1 border px-5 py-3">980-985 kg</p>
                      </div>
                      <div className="flex items-center text-base justify-between">
                        <p className="flex-1 border px-5 py-3 ">
                          Gross Vehicle Weight (kg){" "}
                        </p>
                        <p className="flex-1 border px-5 py-3">1405 kg </p>
                        <p className="flex-1 border px-5 py-3">1405 kg</p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
      <br></br>
      <div className="text-xs px-5 resize rounded-md">
        <p>
          <sup>^</sup>The speed alert system gives audible warning for alert of
          over-speed to driver. When vehicle speed exceeds about 80 km/h,
          primary level warning with two beeps will sound every minute...&nbsp;
          <a
            href="https://www.marutisuzuki.com/cng/swift"
            className="text-blue-600"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read More
          </a>{" "}
        </p>
        <p>
          <sup>#</sup>As certified by Test Agency Under Rule 115 (G) of CMVR
          1989.
        </p>
        <p>
          <sup>*</sup>Claim as on date-supported by JATO Dynamics Ltd.,
          Sedan-Claim valid for CNG in its own class.
        </p>
      </div>
      <br></br>
      <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2 ">
        <div className="space-y-3 my-auto text-left px-6 ">
          <p className="uppercase font-bold text-4xl">New Swift S-CNG</p>
        </div>
        <div className="object-contain h-50 w-100 object-right pt-14 p-3">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between border-b-2  px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                  <span className="text-base uppercase">
                    1. WHAT ARE THE DIFFERENT MODES FOR DRIVING THE NEW SWIFT
                    S-CNG?
                  </span>
                </Disclosure.Button>
                <br></br>
                <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                  <p className="indent-8">
                    There are basically three fuel modes in the New Swift S-CNG,
                    <br></br>
                    <div className="leading-relaxed">
                      <ol>
                        <li className="list-outside">
                          1. <strong>Petrol Mode:</strong> The car starts and
                          drives on petrol in this modes.
                        </li>
                        <li>
                          2. <strong>Auto Mode:</strong> The car starts on
                          petrol and immediately switches to CNG in this mode.
                        </li>
                        <li>
                          3. <strong>Forced CNG Mode:</strong> Here, CNG is used
                          to start thecar.It is advisable to use this mode only
                          when the car’s petrol level is extremely low.
                        </li>
                      </ol>
                    </div>
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between border-b-2 px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                  <span className="text-base uppercase">
                    2. IS THE CNG KIT IN SWIFT S-CNG TESTED FOR SAFETY ?
                  </span>
                </Disclosure.Button>
                <br></br>
                <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                  <p>
                    <strong>Swift S-CNG</strong> has undergone comprehensive
                    testing with the CNG setup in place to provide long-term
                    reliability and durability. The setup has a leak-proof
                    design made of stainless steel pipes and corrosion-resistant
                    joints. It also comes with an integrated wiring harness that
                    eliminates the possibility of short circuits.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between border-b-2 px-5 py-3 text-left text-sm font-medium hover:bg-gray-200 focus:outline-none">
                  <span className="text-base uppercase">
                    3. CAN I GET MY NEW SWIFT S-CNG TUNED FROM OUTSIDE?
                  </span>
                </Disclosure.Button>
                <br></br>
                <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                  <p>
                    It is not recommended to have your new Swift S-CNG tuned by
                    any third party workshop. Always visit your nearest Maruti
                    Suzuki Arena authorized service centre to get the right
                    tuning and calibration for your Swift CNG.
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </>
  );
}

export default SwiftCNG;
